import * as React from 'react';
import Head from 'next/head';
import Link from 'next/link';

import Button from '../components/Button';
import Bnr from '../components/Bnr';
import { datadogLogs } from '@datadog/browser-logs';

const Home = () => {
  const ref = React.createRef();
  return (
    <div className="top">
      <div className="logo">
        <img src="/images/logo/logo.png?v=20240801" alt="Logo" />
      </div>
      <div className="buttons">
        <Link href="/registry">
          <Button type="button" children="新規登録" color="white" />
        </Link>
        <Link href="/login">
          <Button type="button" children="ログイン" color="orange" />
        </Link>
      </div>
      {/* <Bnr /> */}
      <style jsx global>{`
        body {
          padding: 0;
          margin: 0;
        }
      `}</style>
      <style jsx>{`
        .top {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100vh;
          background-image: url('/images/background-large@2x.png?v=20240801');
          background-size: contain;
        }
        .logo {
          text-align: center;
        }
        .logo img {
          max-width: 100%;
        }
        .buttons {
          padding: 0 3em;
        }
        .buttons :global(button) {
          margin: 0.5em 0;
          display: block;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default Home;
