import * as React from 'react';

const Button = React.forwardRef((props: Props, ref: any) => {
  const [color, backgroundColor] = (() => {
    switch (props.color) {
      case 'white':
        return ['#7bccdc', 'white'];
      case 'orange':
        return ['white', '#ed9f5d'];
      case 'blue':
        return ['white', '#7bccdc'];
      case 'grey':
        return ['white', '#ccc'];
      case 'clear':
        return ['#ccc', 'white'];
    }
  })();

  return (
    <button type={props.type} onClick={props.onClick} ref={ref} disabled={props.disabled}>
      {props.children}
      <style jsx>{`
        button {
          display: inline-block;
          padding: 10px 18px;
          border-radius: 30px;
          border: ${props.color === 'clear' ? '1px solid #ccc' : 'none'};
          text-decoratio: none;
          color: ${color};
          background-color: ${backgroundColor};
          min-width: 132px;
        }
      `}</style>
    </button>
  );
});

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  type: 'button' | 'submit';
  color: 'white' | 'orange' | 'blue' | 'grey' | 'clear';
  disabled?: boolean;
}

export default Button;
